import {
  ADD_CODED_ASSIGNMENT_ROUTE,
  ADD_PAPER_ASSIGNMENT_ROUTE,
  ASSIGNMENT_ROUTE,
  CLASSROOM_ADD_CODED_ASSIGNMENT_ROUTE,
  CLASSROOM_ADD_PAPER_ASSIGNMENT_ROUTE,
  CLASSROOM_EDIT_CODED_ASSIGNMENT_ROUTE,
  CLASSROOM_EDIT_PAPER_ASSIGNMENT_ROUTE,
  EDIT_CODED_ASSIGNMENT_ROUTE,
  EDIT_PAPER_ASSIGNMENT_ROUTE,
} from '@fuse/assignment';
import { pluralize, terminology } from '@fuse/terminology';
import { ADMIN_ANNOUNCEMENT_ROUTE } from '../scenes/myClassroom/AdminAnnouncements/constants';
import admissionForm from '../scenes/myClassroom/AdmissionForm/constants';
import setupCourseCatalog from '../scenes/myClassroom/CourseCatalog/constants';
import { generateGradeBookRoutes, gradeBookAdminRoutes } from '../scenes/myClassroom/GradeBook/constants';
import scheduleCourse from '../scenes/myClassroom/ScheduleCourse/constant';
import setupAcademicYear from '../scenes/myClassroom/SetupAcademicYear/constants';
import setupAIHelpdesk from '../scenes/myClassroom/SetupAIHelpdesk/constants';
import setupCourses from '../scenes/myClassroom/SetupCourses/constants';
import setupDegrees from '../scenes/myClassroom/SetupDegrees/constants';
import setupFacility from '../scenes/myClassroom/SetupFacility/constants';
import setUpLiveClassesRoutes from '../scenes/myClassroom/SetupLiveClasses/constants';
import setupPrograms from '../scenes/myClassroom/SetupPrograms/constants';
import setupSchools from '../scenes/myClassroom/SetupSchools/constants';
import setupStaffs from '../scenes/myClassroom/SetupStaffs/constants';
import setupStudents from '../scenes/myClassroom/SetupStudents/constants';

// This page contains the routes of custom url for student and instructor
export const grade = terminology.gradeTitle.toLowerCase();
export const gradeHome = `/${grade}/course`;
export const courseGrade = gradeHome + `/:courseId/course-${pluralize(grade)}`;
export const violationReport = gradeHome + `/:examId/violation-report/:studentId`;
export const classroom = `/${terminology.classroom.toLowerCase().replace(' ', '-')}`;
export const assignment = terminology.assignment.toLowerCase();
export const attendance = terminology.attendance.toLowerCase();
export const quiz = terminology.quiz.toLowerCase();

export const PLAGIARISM_DETAILS_ROUTE = `/assignment/:assignmentId/plagiarism/:studentId`;

export const routeUrl = {
  root: '/',
  home: '/home',
  notFound: '/404',
  profile: '/profile',
  gradeSheet: '/gradesheet',
  globalSchoolSettings: {
    home: '/school/global-settings',
    liveVideo: '/school/global-settings/live-video',
  },
  student: {
    inAppWebViewAnnotation: '/annotation/assignment',
    editProfile: '/profile/edit',
    liveClass: {
      root: '/live-class',
      join: '/live-class/join/:classId',
      recorded: '/live-class/recorded',
      viewRecorded: '/live-class/recorded/:classId',
      schedule: '/live-class/schedule',
    },
    gotoZoom: '/go-live/zoom',
    assignment: {
      root: '/assignment',
      feedback: '/assignment/:assignmentId/feedback',
      submission: '/assignment/:assignmentId/submission',
    },
    grade: '/grade',
    gradebook: '/gradebook',
    classroom: {
      quizzes: {
        list: `/classroom/course/:courseId/quiz`,
        view: `/classroom/course/:courseId/quiz/:quizId`,
        submission: '/classroom/course/:courseId/quiz/:quizId/submission/:quizAttemptId',
      },
      assignments: `/classroom/course/:courseId/assignments`,
      grades: `/classroom/course/:courseId/grades`,
      filePreview: '/classroom/course/:courseId/files/filePreview',
      attendance: '/classroom/attendance',
      course: '/classroom/course',
      announcements: '/classroom/announcements',
    },
    announcements: '/announcements',
    quizzes: {
      list: '/quizzes',
      view: '/quizzes/:quizId',
      submission: '/quizzes/:quizId/submission/:quizAttemptId',
    },
    exams: {
      list: '/exams',
      view: '/exams/:examId',
      resume: '/exams/:examId/:examAttemptId',
      timeUp: '/exams/:examId/timeUp',
      submission: '/exams/:examId/submission/:examAttemptId',
      physicalResume: '/exams/:examId/physical/submission',
    },
    helpdesk: '/helpdesk',
  },
  community: {
    communityHome: '/community',
    feed: '/community/feed',
    groupHome: '/community/group',
    group: '/community/group/:groupId',
    addGroup: '/community/addgroup',
    editGroup: '/community/group/:groupId/editgroup',
  },

  analytics: {
    home: '/analytics',
    attendance: '/analytics/attendance',
    assignment: '/analytics/assignment',
    quiz: '/analytics/quiz',
    exam: '/analytics/exam',
  },
  approvalSettings: {
    home: '/school/approval-settings',
    approval: '/:id/pending-details',
    history: '/:id/history-details',
  },
  classInstructor: {
    attendance: {
      courses: '/courses',
      classes: '/classes',
      classAttendance: '/attendance/classes/:className/program/:programId/class/:classId',
      remarks: '/attendance/remarks',
      home: `/${attendance}`,
      courseAttendance: `/${attendance}/program/:programId/batch/:batchId/course/:courseId`,
      courseAttendanceRemarks: `/${attendance}/program/:programId/batch/:batchId/course/:courseId/${attendance}Remarks/:studentName/:studentId`,
    },
    liveClassroom: `/live-${terminology.class.toLowerCase()}`,
    grade: {
      home: gradeHome,
      courseGrade,
      violationReport,
    },
  },
  quizzes: {
    home: `/${pluralize(quiz)}`,
    createQuiz: `/${pluralize(quiz)}/create`,
    editQuiz: `/${pluralize(quiz)}/:quizId/edit`,
    scoreBoard: `/${pluralize(quiz)}/:quizId/scoreboard`,
    submission: `/${pluralize(quiz)}/:quizId/submission/:attemptId`,
    duplicateCreate: `/${pluralize(quiz)}/:quizId/duplicate/create`,
  },
  exams: {
    list: '/exams',
    createExam: '/exams/create',
    editExam: '/exams/:examId/edit',
    scoreBoard: '/exams/:examId/scoreboard',
    submission: '/exams/:examId/submission/:attemptId',
    duplicateCreate: '/exams/:examId/duplicate/create',
    liveExam: '/exams/:examId/liveExam',
    physical: {
      home: '/exams/physical',
      create: '/exams/physical/create',
      edit: '/exams/physical/:examId/edit',
      duplicateCreate: '/exams/physical/:examId/duplicate/create',
      view: '/exams/physical/:examId/view',
      submission: '/exams/physical/:examId/submission',
    },
  },
  forum: {
    group: '/classroom/course/:courseId',
    specificForumPost: '/classroom/course/:courseId/:postId/:groupId',
  },
  classroom: {
    home: classroom,
    course: `${classroom}/course`,
    overview: `${classroom}/course/:courseId/overview`,
    files: `${classroom}/course/:courseId/files`,
    forum: `${classroom}/course/:courseId/forum`,
    quizzes: {
      home: `${classroom}/course/:courseId/${quiz}`,
      createQuiz: `${classroom}/course/:courseId/${quiz}/create`,
      editQuiz: `${classroom}/course/:courseId/${quiz}/edit/:quizId`,
      viewScoreboard: `${classroom}/course/:courseId/${quiz}/:quizId/scoreboard`,
      submission: `${classroom}/course/:courseId/${quiz}/:quizId/submission/:attemptId`,
    },
    attendance: `${classroom}/course/:courseId/${attendance}`,
    grades: `${classroom}/course/:courseId/${terminology.grade}`,
    assignment: {
      home: `${classroom}/course/:courseId/${assignment}`,
      codedAssignment: {
        add: CLASSROOM_ADD_CODED_ASSIGNMENT_ROUTE,
        edit: CLASSROOM_EDIT_CODED_ASSIGNMENT_ROUTE,
      },
      paperAssignment: {
        addPaperAssignment: CLASSROOM_ADD_PAPER_ASSIGNMENT_ROUTE,
        editPaperAssignment: CLASSROOM_EDIT_PAPER_ASSIGNMENT_ROUTE,
      },
    },
    announcements: `${classroom}/course/:courseId/announcements`,
    onlineLearning: {
      home: `${classroom}/course/:courseId/online-learning`,
      chapterDesc: `${classroom}/course/:courseId/online-learning/module/:moduleId/unit/view/:unitId`,
      addModule: `${classroom}/course/:courseId/online-learning/module/add`,
      addModules: `${classroom}/course/:courseId/online-learning/module/add/addModule`,
      generateModules: `${classroom}/course/:courseId/online-learning/module/add/generateModule`,
      editModule: `${classroom}/course/:courseId/online-learning/module/:moduleId/edit`,
      addUnit: `${classroom}/course/:courseId/online-learning/module/:moduleId/unit/add`,
      editUnit: `${classroom}/course/:courseId/online-learning/module/:moduleId/unit/:unitId/edit`,
      addChapter: `${classroom}/course/:courseId/online-learning/module/:moduleId/unit/:unitId/chapter/add`,
      editChapter: `${classroom}/course/:courseId/online-learning/module/:moduleId/unit/:unitId/chapter/:chapterId/edit`,
      quiz: `${classroom}/course/:courseId/online-learning/module/:moduleId/quiz`,
    },
    filePreview: '/classroom/course/:courseId/files/filePreview',
    studentStatus: {
      home: `${classroom}/course/:courseId/studentstatus`,
      viewReport: `${classroom}/course/:courseId/studentstatus/:studentId/view-report`,
    },
  },

  announcements: '/announcements',
  adminAnnouncements: ADMIN_ANNOUNCEMENT_ROUTE,
  assignment: {
    home: ASSIGNMENT_ROUTE,
    codedAssignment: {
      addAssignment: ADD_CODED_ASSIGNMENT_ROUTE,
      editAssignment: EDIT_CODED_ASSIGNMENT_ROUTE,
    },
    paperAssignment: {
      addAssignment: ADD_PAPER_ASSIGNMENT_ROUTE,
      editPaperAssignment: EDIT_PAPER_ASSIGNMENT_ROUTE,
      paperAssignmentDetail: '/assignment/:assignmentId/paper-assignment-detail',
      viewSubmission: '/assignment/:assignmentId/paper-assignment-submission',
      viewGrades: '/assignment/:assignmentId/paper-assignment-grades',
      plagiarism: '/assignment/:assignmentId/plagiarism',
      plagiarismStudent: '/assignment/:assignmentId/plagiarism/:studentId',
    },
  },
  setupPrograms,
  setupAIHelpdesk,
  setupFacility,
  setupDegrees,
  setupSchools,
  setupCourseCatalog,
  setupStudents,
  setupStaffs,
  scheduleCourse,
  setupCourses,
  setUpLiveClassesRoutes,
  setupAcademicYear,
  admissionForm,
  gradeBookRoutes: generateGradeBookRoutes(``),
  gradeBookAdminRoutes,
  newsFeed: '/newsfeed',
};
